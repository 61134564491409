import React, { useContext, useEffect , forwardRef} from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FLISContext } from "../context/FLISContext";
import { FormHookFileUpload, FormHookInput, FormHookSelect, FormHookTextArea } from "./FormHook/ReactFormHookFields";
import { FormHookFileUploadHandler } from "./FormHook/FormHookDocument";
import { emailPattern, formatPhoneNumber, telephonePattern } from "./DataSource";
import { emailNotValid, telephoneNotValid } from "../utils/messages";

export const VesselOwnerDetails = ({formState,control, register, onSubmit,values, setValue, reset, watch ,  ...props  }) => {


    const { fields, append, remove } = useFieldArray({
      control,
      name: 'vessel_owner_details',
    });

    const {errors} = formState;

    const {selectedMap} = useContext(FLISContext);
    useEffect(() => {

      if(selectedMap.vessel_owner_details){
       for(let i=0;i<selectedMap.vessel_owner_details?.length;i++){
        setValue(`vessel_owner_details[${i}]`, selectedMap.vessel_owner_details[i]);
       }
      }

    }, [selectedMap.vessel_owner_details]);



    useEffect(() => {
      async function setFormValues() {
        // const {vessel_owner_details} = await values();
        if(selectedMap.vessel_owner_details){
          // setValue('vessel_owner_details', selectedMap.vessel_owner_details);
          selectedMap?.vessel_owner_details?.forEach((vod, i) => {
            const field = document.querySelector(`input[name="vessel_owner_details[${i}].embedded_photo_of_owner"]`);
            if (field) {
              // field.files = selectedMap?.vessel_owner_details[i]?.embedded_photo_of_owner;
            }
          });
        }
      }
      setFormValues();
    }, [selectedMap.vessel_owner_details]);
   
    return (
      <div className="form-container">
        <fieldset>
          <form
            onSubmit={onSubmit}
          >
             {fields.map((group, groupIndex) => {
              return (
                <div
                  key={group.id}
                  className="shadow p-3 mb-3 bg-white rounded"
                >
                  <div className="d-flex flex-row form-heading">
                    <div
                      style={{ marginRight: "10px" }}
                    >{`${"Vessel Owner Details"} #${groupIndex + 1}`}</div>

                    <div>
                      <button
                        className="btn btn-warning"
                        onClick={() => {
                          remove(groupIndex);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>

                  <div className="d-flex-wrap flex-row justify-content-start">
                    <FormHookInput
                      register={register}
                      error={
                        errors.vessel_owner_details?.[groupIndex]?.name_of_owner
                          ?.message
                      }
                      placeholder="Name of Owner"
                      label="Name of Owner"
                      regFieldName={`vessel_owner_details[${groupIndex}].name_of_owner`}
                      type="text"
                      validations={{
                        maxLength: { value: 100, message: "Max length is 100" },
                      }}
                    />

                    <FormHookInput
                      register={register}
                      error={
                        errors.vessel_owner_details?.[groupIndex]?.company_name
                          ?.message
                      }
                      label="Company Name"
                      placeholder="Company Name"
                      regFieldName={`vessel_owner_details[${groupIndex}].company_name`}
                      type="text"
                      isRequired={false}
                      validations={{
                        maxLength: { value: 400, message: "Max length is 400" },
                      }}
                    />

                    <FormHookInput
                      register={register}
                      error={
                        errors.vessel_owner_details?.[groupIndex]
                          ?.owner_registration_number?.message
                      }
                      label="Owner Registration #"
                      placeholder="Owner Registration #"
                      regFieldName={`vessel_owner_details[${groupIndex}].owner_registration_number`}
                      type="text"
                      validations={{
                        maxLength: { value: 100, message: "Max length is 100" },
                      }}
                      isRequired={false}
                    />

                    <FormHookInput
                      register={register}
                      error={
                        errors.vessel_owner_details?.[groupIndex]
                          ?.telephone_number?.message
                      }
                      label="Telephone #"
                      placeholder="Telephone #"
                      regFieldName={`vessel_owner_details[${groupIndex}].telephone_number`}
                      type="text"
                      validations={{
                        pattern: {
                          value: telephonePattern,
                          message: telephoneNotValid,
                        },
                      }}
                      onChange={formatPhoneNumber}
                    />

                    <FormHookSelect
                      label="Select Nationality"
                      register={register}
                      regFieldName={`vessel_owner_details[${groupIndex}].domicile`}
                      options={["Local", "Foreign"]}
                      error={
                        errors.vessel_owner_details?.[groupIndex]?.domicile
                          ?.message
                      }
                    />

                    <FormHookInput
                      register={register}
                      error={
                        errors.vessel_owner_details?.[groupIndex]?.email
                          ?.message
                      }
                      label="Email"
                      placeholder="Email"
                      regFieldName={`vessel_owner_details[${groupIndex}].email`}
                      type="text"
                      isRequired={false}
                      validations={{
                        pattern: {
                          value: emailPattern,
                          message: emailNotValid,
                        },
                      }}
                    />

                    <FormHookTextArea
                      register={register}
                      error={
                        errors.vessel_owner_details?.[groupIndex]?.address
                          ?.message
                      }
                      label="Address"
                      placeholder="Address"
                      regFieldName={`vessel_owner_details[${groupIndex}].address`}
                      cols={32}
                      rows={1}
                    />

                    <FormHookFileUploadHandler
                      error={
                        errors.vessel_owner_details?.[groupIndex]
                          ?.embedded_photo_of_owner?.message
                      }
                      register={register}
                      watch={watch}
                      fieldClassName="field-block-control max-250"
                      parentClass="individual-field max-250"
                      // label="Embedded Photo of Owner"
                      label="ID of Owner"
                      regFieldName={`vessel_owner_details[${groupIndex}].embedded_photo_of_owner`}
                      isPreviewable={false}
                      setValue={setValue}
                      removeFile={(id) => setValue(id, null)}
                    />
                  </div>
                </div>
              );
             })}
         
          </form>
        </fieldset>

        <div className="d-flex flex-row-reverse">
            <button type="button" onClick={() => {
            append({id: Math.random()});
        }} className="btn btn-primary">Add Vessel Owner Details</button>
        </div>
      </div>
    );
};
