// import { default as ReactSelect } from "react-select";
// import { Option } from "./Option";
import React, { useContext, useState } from "react";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Tippy from "@tippyjs/react";
import {
  FISHING_VESSEL_LICENCE,
  fisherVesselLicenceCategory,
  fisherVesselLicenceTypeSubcateory,
  typeOfTransactions,
  fishingArea,
  getSVGImage,
  getRectBoxStylingWithSingleValue,
  getRectBoxStylingWithMultiTypeValues,
  InternalNavigator,
  TargetFish_Commercial_Industrial,
  CommercialCarrierTypeofCarrier,
  Vessel_Fishing_GearType,
  MainGearOwnedBy,
  getAnimationStyle,
  recreation_type_of_licence,
  CommercialCarrierTypeOfCarrierVessel,
  CommercialTypeOfVessel,
  onChangeMultiSelect,
  ACCESS_AGREEMENTS_FOREIGN_FISHING,
  RECREATIONAL,
  COMMERCIAL,
  ARTISANAL_FISHING,
  INDUSTRIAL_FISHING,
  FisheryTarget,
  CommercialTypeofFish,
  NEW_LICENCE,
  specieal_species_allow,
  SEA_CUCUMBER,
  SPINY_LOBSTER,
  CONCH,
  VesselClasses,
  fishingAreaFishingVessel,
  fishingVesselLicenceTypeSubCategoryDescription,
  fishingVesselLicenceTypeDecription,
  OTHER_FISHER,
  baseLocations,
  BASE_LOCATION_INLAND,
  InlandList,
  BASE_LOCATION_PORT,
  regionParishList,
  regionParishPort,
  regionListForInland,
  regionInland,
  SUBSISTENCE,
  ENTERTAINMENT,
} from "../../components/DataSource";
import {
  CustomInputContainer,
  onChangeInput,
} from "../../components/CustomInputContainer";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { FLISContext } from "../../context/FLISContext";
import { VesselOwnerDetails } from "../../components/VesselOwnerDetails";
import { useForm } from "react-hook-form";
import { VesselDetails } from "../../components/VesselDetails";
import BoxChoiceContainer from "../../components/BoxChoiceContainer";
import {
  getNameOfApplicant,
  isDateInRange,
  shouldAskForNFARegNumber,
  validateKeys,
} from "../../utils/utils";
import {
  formInvalidMessage,
  inputPreviousNumber,
  selectionNotFound,
} from "../../utils/messages";
import { alertService } from "../../_services";
import { GearInformation } from "./GearInformation";
import NFARegNumberSelection from "../../components/NFARegNumberQuestion";
import { vesselClasses } from "../AquacultureFishingVessel/AquaFishingVesselDataSource";
import { Tooltip } from "@material-ui/core";
import { ReactSelect } from "../../components/FormHook/ReactFormHookFields";
import { postData } from "../../services/service-call";
import { ROUTES } from "../../config";
import { icmPropMapping } from "../../components/IcmPropMappingtoUI";
import { set } from "lodash";

export default function FishingVesselLicenceTypeSelection(props) {
  const history = useHistory();

  const { selectedMap, setSelectedMap, currentEntity } =
    useContext(FLISContext);

  const [selectionCategory, setSelectionCategory] = useState(
    selectedMap.selectionCategory ?? 1
  );

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new window.google.maps.LatLngBounds();
    fishingArea?.forEach(({ lat, lng }) => {
      if (lat !== 0) {
        bounds.extend({ lat, lng });
      }
    });
    map.fitBounds(bounds);
  };

  const handleMarkerClick = (id, lat, lng, address) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, address });
    setIsOpen(true);
  };

  const prePopulateVesselOwnerDetails = () => {

    return {
      name_of_owner: getNameOfApplicant(selectedMap),
      company_name: getNameOfApplicant(selectedMap),
      owner_registration_number: selectedMap.nfa_registration_number,
      telephone_number: selectedMap.cell_phone,
      domicile: selectedMap.domicile,
      email: selectedMap.email,
      address: selectedMap.address,
      
    };
  };

  const vesselOwnerDetailsForm = useForm({
    defaultValues: {
      vessel_owner_details: selectedMap.vessel_owner_details ?? [
        { id: Math.random(), ...prePopulateVesselOwnerDetails() },
      ],
    },
    mode: "all",
  });

  const gearInformationForm = useForm({
    defaultValues: {
      gear_information: selectedMap.gear_information || {},
    },
    mode: "all",
  });

  const vesselDetailsForm = useForm({
    defaultValues: {
      fishing_vessel_details: selectedMap.fishing_vessel_details || {
        engine_details: [{ id: Math.random() }],
        vessel_identification_number:
          selectedMap.nfa_vessel_registration_number,
      },
    },
    mode: "all",
  });

  const {
    handleSubmit: handleVODSubmit,
    control: vodFormControl,
    trigger: triggerValidateVOD,
    getValues: getVesselOwnerDetailsValues,
    register: registerVOD,
    formState: vodFormState,
    setValue,
    reset,
    watch: watchVOD,
  } = vesselOwnerDetailsForm;

  const {
    control: giFormControl,
    trigger: triggerValidateGI,
    getValues: getGearInfoValues,
    register: registerGI,
    formState: giFormState,
    setValue: setGearInfoValues,
    watch: watchGearInfo,
  } = gearInformationForm;

  const [validationMsgVesselPort, setValidationMessageVesselPort] =
    useState("");
  // const gearInfo = watchGearInfo('gear_information', {deep: true});
  // const {gearInfoErrors} = giFormState;

  const {
    handleSubmit: handleVDSubmit,
    control: vdFormControl,
    trigger: triggerValidateVD,
    getValues: getVesselDetailsValues,
    register: registerVD,
    watch: watchVD,
    formState: vdFormState,
    setValue: setVesselDetails,
  } = vesselDetailsForm;

  const onSubmit = (data) => {
    console.log(data);
  };

  const validateVODForm = async () => {
    try {
      const isFormValid = await triggerValidateVOD();

      // can get values from this
      const data = await getVesselOwnerDetailsValues();
      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map,
            vessel_owner_details: data.vessel_owner_details,
          };
        });

        handleVODSubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  const validateVDForm = async () => {
    try {
      const isFormValid = await triggerValidateVD();

      // can get values from this
      const data = await getVesselDetailsValues();

      if (isFormValid) {
        setSelectedMap((map) => {
          return {
            ...map,
            fishing_vessel_details: data.fishing_vessel_details,
          };
        });
        handleVDSubmit(onSubmit)();
      } else {
        console.log("Some forms are invalid");
      }
      return isFormValid;
    } catch (error) {
      console.error("Error while triggering validation:", error);
      return false;
    }
  };

  return (
    <div
      className="d-flex flex-column"
      style={{ width: "100%", padding: "10px 10%" }}
    >
      {selectionCategory === 1 && (
        <>
          <NFARegNumberSelection
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            setSelectionCategory={setSelectionCategory}
            history={history}
            selectedMap={selectedMap}
          />
        </>
      )}

      {selectionCategory === 2 && (
        <div style={getAnimationStyle(selectionCategory === 2)}>
          <BoxChoiceContainer
            listingHeading={"Select type of Transaction"}
            listingOptions={typeOfTransactions[FISHING_VESSEL_LICENCE]}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"type_of_transaction"}
            listingStyle="justify-content-center"
            onClick={(trans, i) => {
              if (trans === NEW_LICENCE) {
                setSelectionCategory(selectionCategory + 1);
              }
            }}
          ></BoxChoiceContainer>

          {/* {["Renewal", "Transfer Ownership", "Replacement Licence"].indexOf(
            selectedMap["type_of_transaction"]
          ) !== -1 && (  <CustomInputContainer
            heading={"Enter the Fishing Vessel Licence Number"}
            prop_key={"previous_vessel_licence_number"}
            placeholder={"Enter the Fishing Vessel Licence Number"}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
          ></CustomInputContainer>
           
          )} */}

          {
            <div className="d-flex flex-column">
              {["Renewal", "Transfer Ownership", "Replacement Licence"].indexOf(
                selectedMap["type_of_transaction"]
              ) !== -1 && (
                <>
                  <div className="rectangle-box-small-heading">
                    Enter NFA Vessel Registration Number
                  </div>

                  <div className="d-flex flex-row justify-content-center">
                    <input
                      type="text"
                      name="nfa_vessel_registration_number"
                      onChange={(e) => onChangeInput(e, setSelectedMap)}
                      value={selectedMap.nfa_vessel_registration_number}
                      className="col-sm-5 form-control form-control-sm"
                      placeholder={"Enter NFA Vessel Registration Number"}
                    />
                  </div>
                  <div className="d-flex flex-row justify-content-center">
                    <span style={{ color: "red" }}>
                      {validationMsgVesselPort}
                    </span>
                  </div>
                </>
              )}
            </div>
          }

          <InternalNavigator
            nextValue={"Next"}
            nextAction={async () => {
              if (!validateKeys(selectedMap, ["type_of_transaction"])) {
                alertService.warn(selectionNotFound);
                return;
              }

              if (
                [
                  "Renewal",
                  "Transfer ownership",
                  "Replacement Licence",
                ].indexOf(selectedMap.type_of_transaction) !== -1 &&
                false &&
                !validateKeys(selectedMap, ["previous_vessel_licence_number"])
              ) {
                alertService.warn(
                  inputPreviousNumber("Vessel Licence Number"),
                  {
                    autoClose: true,
                  }
                );
                return;
              } else if (
                shouldAskForNFARegNumber(selectedMap) &&
                !validateKeys(selectedMap, ["nfa_registration_number"])
              ) {
                alertService.warn(
                  inputPreviousNumber("NFA Registration Number")
                );
                return;
              } else if (
                [
                  "Renewal",
                  "Transfer ownership",
                  "Replacement Licence",
                ].indexOf(selectedMap.type_of_transaction) !== -1
              ) {
                if (
                  !validateKeys(selectedMap, [
                    // "previous_vessel_licence_number",
                    "nfa_vessel_registration_number",
                  ])
                ) {
                  alertService.warn(
                    inputPreviousNumber(
                      // "Previous Vessel Licence Number and Vessel Port Registration Number"
                      "NFA Vessel Registration Number"
                    ),
                    {
                      autoClose: true,
                    }
                  );
                  return;
                }

                // test for correct code format

                const newSchemaRegex =
                  /^[A-Za-z]{2}-[A-Za-z][0-9]{3,7}-[A-Za-z .]{1,5}$/;
                const oldSchemaRegex = /^[A-Za-z .]{1,5}-[0-9]{3,7}$/;
                const specialSchemaRegex = /^6Y-[0-9]{3,5}-(PSC|PNC|MC)$/;
                if (
                  newSchemaRegex.test(
                    selectedMap.nfa_vessel_registration_number
                  ) ||
                  oldSchemaRegex.test(
                    selectedMap.nfa_vessel_registration_number
                  ) ||
                  specialSchemaRegex.test(
                    selectedMap.nfa_vessel_registration_number
                  )
                ) {
                  setValidationMessageVesselPort("");
                } else {
                  alertService.warn(
                    "The format of Vessel Port Registration Number is not correct, it should be XX-FXXX-XX or XX-XXX",
                    {
                      autoClose: true,
                    }
                  );
                  setValidationMessageVesselPort(
                    "The format of Vessel Port Registration Number is not correct, it should be XX-FXXX-XX or XX-XXX"
                  );
                  return;
                }

                

                if (process.env.REACT_APP_DO_RENEWAL_API == 1) {
                  // do api call for getting the renewal data here
                  const result = await postData({
                    url: `${ROUTES.getVesselRenewalData}`,
                    body: {
                      // to_be_decided: selectedMap.previous_permit_number,
                      nfaRegistrationNumber:
                        selectedMap.nfa_registration_number,
                      entityNumber: currentEntity.entity_number,
                      // "nfaRegistrationNumber": "123455",
                      // "entityNumber": "IN-5815446",
                    },
                  });

                  let map = {};
                  Object.keys(result?.data?.result ?? []).forEach((key) => {
                    // map[icmPropMapping['APPLICATION_FOR_LICENCE'][key] || ''] = result?.data?.result[key];
                    if (icmPropMapping["APPLICATION_FOR_FISHING_VESSEL"][key])
                      set(
                        map,
                        icmPropMapping["APPLICATION_FOR_FISHING_VESSEL"][key],
                        result?.data?.result[key]
                      );
                  });
                  console.log(map);
                  setSelectedMap((data) => {
                    return {
                      ...data,
                      ...map,
                    };
                  });
                }
              }


              setVesselDetails(
                "fishing_vessel_details.vessel_identification_number",
                selectedMap.nfa_vessel_registration_number
              );

              setSelectionCategory(selectionCategory + 1);
            }}
            prevValue={"Previous"}
            previousAction={() => {
              setSelectionCategory(selectionCategory - 1);
            }}
          />
        </div>
      )}

      {selectionCategory === 3 && (
        <div style={getAnimationStyle(selectionCategory === 3)}>
          <>
            {/* for fisher vessel licence category */}
            <div className="d-flex flex-column">
              {fisherVesselLicenceCategory[FISHING_VESSEL_LICENCE]?.length && (
                <div className="rectangle-box-small-heading">
                  What Type of Licence do you want to apply for?
                </div>
              )}

              <div className="d-flex flex-wrap justify-content-center">
                {fisherVesselLicenceCategory[FISHING_VESSEL_LICENCE]?.map(
                  (licence_type) => {
                    return (
                      <Tooltip
                        placement="bottom"
                        title={fishingVesselLicenceTypeDecription[licence_type]}
                      >
                        <div
                          key={licence_type}
                          onClick={() => {
                            setSelectedMap((map) => {
                              return {
                                ...map,
                                licence_type: licence_type,
                              };
                            });

                            if (
                              licence_type !== ACCESS_AGREEMENTS_FOREIGN_FISHING
                            ) {
                              setSelectionCategory(selectionCategory + 1);
                            } else if (
                              licence_type === ACCESS_AGREEMENTS_FOREIGN_FISHING
                            ) {
                              // ACCESS_AGREEMENTS_FOREIGN_FISHING doesn't have any subcategory
                              setSelectionCategory(selectionCategory + 4);
                            }
                          }}
                          className="p-2 license-cat-container"
                          style={getRectBoxStylingWithSingleValue(
                            selectedMap,
                            "licence_type",
                            licence_type
                          )}
                        >
                          <div style={{ textAlign: "center" }}>
                            {getSVGImage(
                              48,
                              84,
                              selectedMap["licence_type"] === licence_type
                                ? "#00b7fd"
                                : "##00b7fd"
                            )}

                            <div className="license-cat-container-text">
                              {licence_type}
                            </div>
                          </div>
                        </div>
                      </Tooltip>
                    );
                  }
                )}
              </div>
            </div>

            <div className="d-flex flex-column">
              <InternalNavigator
                prevValue={"Previous"}
                previousAction={() => {
                  setSelectionCategory(selectionCategory - 1);
                }}
                nextValue={"Next"}
                nextAction={() => {
                  if (!validateKeys(selectedMap, ["licence_type"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }

                  if (
                    selectedMap.licence_type !==
                    ACCESS_AGREEMENTS_FOREIGN_FISHING
                  ) {
                    setSelectionCategory(selectionCategory + 1);
                  } else if (
                    selectedMap.licence_type ===
                    ACCESS_AGREEMENTS_FOREIGN_FISHING
                  ) {
                    // ACCESS_AGREEMENTS_FOREIGN_FISHING doesn't have any subcategory
                    setSelectionCategory(selectionCategory + 4);
                  }
                }}
              />
            </div>
          </>
        </div>
      )}

      {selectionCategory === 4 && (
        <div style={getAnimationStyle(selectionCategory === 4)}>
          <div className="d-flex flex-column">
            {fisherVesselLicenceTypeSubcateory[selectedMap?.licence_type]
              ?.length && (
              <div className="rectangle-box-small-heading">
                What Category of Licence do you want to apply for?
              </div>
            )}

            <div className="d-flex flex-wrap justify-content-center">
              {fisherVesselLicenceTypeSubcateory[
                selectedMap?.licence_type
              ]?.map((licence_type, i) => {
                // https://mail.google.com/mail/u/1/#sent/FMfcgzQXJkXsdbcTvZTZpVpxGGdxVLKh
                // asked to be hidden
                if ([ENTERTAINMENT].indexOf(licence_type) >= 0)
                  return null;

                return (
                  <div
                    key={licence_type}
                    onClick={() => {
                      if (selectedMap.licence_type === RECREATIONAL) {
                        // onChangeMultiSelect(
                        //   {
                        //     target: {
                        //       name: "recreational_licence_type",
                        //       value: licence_type,
                        //     },
                        //   },
                        //   selectedMap,
                        //   setSelectedMap
                        // );
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            recreational_licence_type: licence_type,
                          };
                        });
                      } else {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            commercial_licence_type: licence_type,
                            vessel_commercial_carrier_type: "Artisanal Carrier",
                          };
                        });
                      }

                      setSelectionCategory(selectionCategory + 1);
                    }}
                    className="p-2 license-cat-container"
                    style={
                      selectedMap.licence_type !== "Recreational"
                        ? getRectBoxStylingWithMultiTypeValues(
                            selectedMap,
                            "commercial_licence_type",
                            licence_type
                          )
                        : getRectBoxStylingWithMultiTypeValues(
                            selectedMap,
                            "recreational_licence_type",
                            licence_type
                          )
                    }
                  >
                    <Tooltip
                      placement="bottom"
                      title={
                        fishingVesselLicenceTypeSubCategoryDescription[
                          licence_type
                        ] ?? ""
                      }
                    >
                      <div style={{ textAlign: "center" }}>
                        {getSVGImage(48, 84, "#00b7fd")}
                        <div className="license-cat-container-text">
                          {licence_type}
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          </div>

          {/* <div className="d-flex flex-column">
            {selectedMap.commercial_licence_type === "Carrier Fishing" &&
              selectedMap.licence_type === COMMERCIAL && (
                <div className="rectangle-box-small-heading">
                  Select the Type of Carrier
                </div>
              )}

            <div className="d-flex flex-wrap justify-content-between  ">
              {CommercialCarrierTypeofCarrier[FISHING_VESSEL_LICENCE]?.[
                selectedMap.licence_type
              ]?.[selectedMap.commercial_licence_type]?.map(
                (vessel_commercial_carrier_type) => {
                  return (
                    <div
                      key={vessel_commercial_carrier_type}
                      onClick={() => {
                        setSelectedMap((map) => {
                          return {
                            ...map,
                            vessel_commercial_carrier_type,
                          };
                        });
                      }}
                      className="p-2 license-cat-container"
                      style={getRectBoxStylingWithSingleValue(
                        selectedMap,
                        "vessel_commercial_carrier_type",
                        vessel_commercial_carrier_type
                      )}
                    >
                      <div style={{ textAlign: "center" }}>
                        {getSVGImage(
                          48,
                          84,
                          selectedMap["vessel_commercial_carrier_type"] ===
                            vessel_commercial_carrier_type
                            ? "#00b7fd"
                            : "#00b7fd"
                        )}

                        <div className="license-cat-container-text">
                          {vessel_commercial_carrier_type}
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div> */}

          <div className="d-flex flex-column">
            <InternalNavigator
              prevValue={"Previous"}
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
              nextValue={"Next"}
              nextAction={() => {
                if (selectedMap.licence_type === RECREATIONAL) {
                  // validations for RECREATIONAL
                  if (
                    !validateKeys(selectedMap, ["recreational_licence_type"])
                  ) {
                    alertService.warn(selectionNotFound);
                    return;
                  }
                } else if (selectedMap.licence_type === COMMERCIAL) {
                  // validations for COMMERCIAL
                  if (!validateKeys(selectedMap, ["commercial_licence_type"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }
                  // specific to "Carrier Fishing"
                  if (
                    selectedMap.commercial_licence_type === "Carrier Fishing"
                  ) {
                    if (
                      !validateKeys(selectedMap, [
                        "vessel_commercial_carrier_type",
                      ])
                    ) {
                      alertService.warn(selectionNotFound);
                      return;
                    }
                  }
                }

                setSelectionCategory(selectionCategory + 1);
              }}
            />
          </div>
        </div>
      )}

      {selectionCategory === 5 && (
        <div style={getAnimationStyle(selectionCategory === 5)}>
          {/* first selection */}

          {/* {selectedMap.licence_type} */}
          {/* {selectedMap.fisher_vessel_licence_category} */}
          {selectedMap.commercial_licence_type === "Artisanal Fishing" &&
            selectedMap.licence_type === "Commercial" && (
              <div>
                <BoxChoiceContainer
                  listingHeading={"Select Type of Vessel"}
                  listingOptions={
                    CommercialTypeOfVessel[selectedMap.commercial_licence_type]
                  }
                  prop_key={"commercial_artisanal_vessel_type"}
                  selectedMap={selectedMap}
                  setSelectedMap={setSelectedMap}
                  textStyle="rectangle-box-small-text"
                  listingStyle="justify-content-center"
                />

                <BoxChoiceContainer
                  listingHeading={"Select Target Fish"}
                  listingOptions={
                    CommercialTypeofFish[selectedMap.commercial_licence_type]
                  }
                  prop_key={"commercial_artisanal_target_fish_type"}
                  selectedMap={selectedMap}
                  setSelectedMap={setSelectedMap}
                  textStyle="rectangle-box-small-text"
                  listingStyle="justify-content-center"
                  listingDescriptions={[
                    "",
                    "",
                    "",
                    // specieal_species_allow[SPINY_LOBSTER].description,
                    // specieal_species_allow[SPINY_LOBSTER].description,
                    specieal_species_allow[CONCH].description,
                    specieal_species_allow[SEA_CUCUMBER].description,
                  ]}
                  listingDisabled={[
                    false,
                    false,
                    false,
                    // !isDateInRange(
                    //   specieal_species_allow[SPINY_LOBSTER].start_date,
                    //   specieal_species_allow[SPINY_LOBSTER].end_date
                    // ),
                    // !isDateInRange(
                    //   specieal_species_allow[SPINY_LOBSTER].start_date,
                    //   specieal_species_allow[SPINY_LOBSTER].end_date
                    // ),
                    !isDateInRange(
                      specieal_species_allow[CONCH].start_date,
                      specieal_species_allow[CONCH].end_date
                    ),
                    !isDateInRange(
                      specieal_species_allow[SEA_CUCUMBER].start_date,
                      specieal_species_allow[SEA_CUCUMBER].end_date
                    ),
                    false,
                    false,
                  ]}
                />

                {selectedMap?.commercial_artisanal_target_fish_type?.indexOf(
                  OTHER_FISHER
                ) >= 0 && (
                  <CustomInputContainer
                    heading={`Enter the ${OTHER_FISHER}`}
                    prop_key={"other_fisher_type"}
                    placeholder={"Enter Other Fisher Type"}
                    selectedMap={selectedMap}
                    setSelectedMap={setSelectedMap}
                  ></CustomInputContainer>
                )}
              </div>
            )}

          {selectedMap.commercial_licence_type === "Industrial Fishing" &&
            selectedMap.licence_type === "Commercial" && (
              <div className="d-flex flex-column">
                <BoxChoiceContainer
                  listingHeading={"Fishery Type (a)"}
                  listingOptions={FisheryTarget.a}
                  selectedMap={selectedMap}
                  setSelectedMap={setSelectedMap}
                  selectionCategory={selectionCategory}
                  prop_key={"fishery_type_a"}
                  listingStyle="justify-content-center"
                  isMultiSelect={true}
                  onClick={(trans) => {
                    setSelectedMap((map) => {
                      return {
                        ...map,
                        fishery_type: trans,
                        fishery_type_b: [],
                      };
                    });
                  }}
                  listingDescriptions={[
                    // specieal_species_allow[SPINY_LOBSTER].description,
                    "",
                    "",
                    specieal_species_allow[SEA_CUCUMBER].description,
                    "",
                  ]}
                  listingDisabled={[
                    // !isDateInRange(
                    //   specieal_species_allow[SPINY_LOBSTER].start_date,
                    //   specieal_species_allow[SPINY_LOBSTER].end_date
                    // ),
                    false,
                    false,
                    !isDateInRange(
                      specieal_species_allow[SEA_CUCUMBER].start_date,
                      specieal_species_allow[SEA_CUCUMBER].end_date
                    ),
                    false,
                  ]}
                ></BoxChoiceContainer>

                <BoxChoiceContainer
                  listingHeading={"Fishery Type (b)"}
                  listingOptions={FisheryTarget.b}
                  selectedMap={selectedMap}
                  setSelectedMap={setSelectedMap}
                  selectionCategory={selectionCategory}
                  prop_key={"fishery_type_b"}
                  listingStyle="justify-content-center"
                  isMultiSelect={true}
                  onClick={(trans) => {
                    setSelectedMap((map) => {
                      return {
                        ...map,
                        fishery_type: trans,
                        fishery_type_a: [],
                      };
                    });
                  }}
                  listingDescriptions={[
                    specieal_species_allow[CONCH].description,
                    "",
                    specieal_species_allow[SEA_CUCUMBER].description,
                    "",
                  ]}
                  listingDisabled={[
                    !isDateInRange(
                      specieal_species_allow[CONCH].start_date,
                      specieal_species_allow[CONCH].end_date
                    ),
                    false,
                    !isDateInRange(
                      specieal_species_allow[SEA_CUCUMBER].start_date,
                      specieal_species_allow[SEA_CUCUMBER].end_date
                    ),
                    false,
                  ]}
                ></BoxChoiceContainer>
              </div>
            )}

          {selectedMap.commercial_licence_type === "Carrier Fishing" &&
            selectedMap.vessel_commercial_carrier_type ===
              "Industrial Carrier" &&
            selectedMap.licence_type === "Commercial" && (
              <div className="d-flex flex-column">
                <div className="rectangle-box-small-heading">
                  Target Fish (Optional)
                </div>

                <div className="d-flex flex-wrap justify-content-center">
                  {TargetFish_Commercial_Industrial[FISHING_VESSEL_LICENCE]?.[
                    selectedMap.licence_type
                  ]?.[selectedMap.commercial_licence_type]?.[
                    selectedMap.vessel_commercial_carrier_type
                  ]["Species"]?.map((target_fish) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedMap((map) => {
                            return {
                              ...map,
                              target_fish: map.target_fish ? "" : target_fish,
                            };
                          });
                        }}
                        className="p-2 license-cat-container"
                        style={getRectBoxStylingWithSingleValue(
                          selectedMap,
                          "target_fish",
                          target_fish
                        )}
                      >
                        <div style={{ textAlign: "center" }}>
                          {getSVGImage(
                            48,
                            84,
                            selectedMap["target_fish"] === target_fish
                              ? "#00b7fd"
                              : "#00b7fd"
                          )}

                          <div className="license-cat-container-text ">
                            {target_fish}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="rectangle-box-small-heading">
                  Special Target Fish
                </div>

                <div className="d-flex flex-wrap justify-content-center">
                  {TargetFish_Commercial_Industrial[FISHING_VESSEL_LICENCE]?.[
                    selectedMap.licence_type
                  ]?.[selectedMap.commercial_licence_type]?.[
                    selectedMap.vessel_commercial_carrier_type
                  ]["Special_Species"]?.map((special_target_fish) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedMap((map) => {
                            let toCheck =
                              map.special_target_fish === special_target_fish
                                ? ""
                                : special_target_fish;
                            return {
                              ...map,
                              special_target_fish: toCheck,
                            };
                          });
                        }}
                        className="p-2 license-cat-container"
                        style={getRectBoxStylingWithSingleValue(
                          selectedMap,
                          "special_target_fish",
                          special_target_fish
                        )}
                      >
                        <div style={{ textAlign: "center" }}>
                          {getSVGImage(
                            48,
                            84,
                            selectedMap["special_target_fish"] ===
                              special_target_fish
                              ? "#00b7fd"
                              : "#00b7fd"
                          )}

                          <div className="license-cat-container-text ">
                            {special_target_fish}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

          {/* first type - commercials third Carrier Fishing Selection  - 2nd Artisanal Carrier to show type of carrier vessel */}
          {selectedMap.commercial_licence_type === "Carrier Fishing" &&
            selectedMap.vessel_commercial_carrier_type ===
              "Artisanal Carrier" &&
            selectedMap.licence_type === "Commercial" && (
              <div className="d-flex flex-column">
                <div className="rectangle-box-small-heading">
                  Select one of the below type Carrier Vessel
                </div>

                <div className="d-flex flex-wrap justify-content-between">
                  {CommercialCarrierTypeOfCarrierVessel[
                    selectedMap?.vessel_commercial_carrier_type
                  ]?.map((vessel_type) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedMap((map) => {
                            return {
                              ...map,
                              vessel_type,
                            };
                          });
                        }}
                        className="p-2 license-cat-container license-cat-wide-container"
                        style={getRectBoxStylingWithSingleValue(
                          selectedMap,
                          "vessel_type",
                          vessel_type
                        )}
                      >
                        <div style={{ textAlign: "center" }}>
                          {getSVGImage(
                            48,
                            84,
                            selectedMap["vessel_type"] === vessel_type
                              ? "#00b7fd"
                              : "#00b7fd"
                          )}

                          <div className="license-cat-container-text license-wide-cat-container-text">
                            {vessel_type}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

          {/* second selection */}
          {selectedMap.licence_type === "Recreational" && (
            <div>
              {/* {selectedMap.recreational_licence_type?.map((rec_lic, i) => (
                <BoxChoiceContainer
                  key={i}
                  listingHeading={`Select Vessel Class for ${rec_lic}`}
                  listingOptions={recreation_type_of_licence[rec_lic].values}
                  prop_key={recreation_type_of_licence[rec_lic].key}
                  selectedMap={selectedMap}
                  selectionCategory={selectionCategory}
                  setSelectedMap={setSelectedMap}
                  listingStyle="justify-content-center"
                  boxStyle="sub-cat-container"
                  textStyle="rectangle-box-small-text"
                  imageWidth={32}
                  imageHeight={32}
                />
              ))} */}


                <BoxChoiceContainer
                  listingHeading={`Select Vessel Class for ${selectedMap.recreational_licence_type}`}
                  listingOptions={recreation_type_of_licence[selectedMap.recreational_licence_type].values}
                  prop_key={recreation_type_of_licence[selectedMap.recreational_licence_type].key}
                  selectedMap={selectedMap}
                  selectionCategory={selectionCategory}
                  setSelectedMap={setSelectedMap}
                  listingStyle="justify-content-center"
                  boxStyle="sub-cat-container"
                  textStyle="rectangle-box-small-text"
                  imageWidth={32}
                  imageHeight={32}
                />
            </div>
          )}

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={() => {
                if (
                  selectedMap.commercial_licence_type === ARTISANAL_FISHING &&
                  selectedMap.licence_type === COMMERCIAL
                ) {
                  if (
                    !validateKeys(selectedMap, [
                      "commercial_artisanal_vessel_type",
                      "commercial_artisanal_target_fish_type",
                    ])
                  ) {
                    alertService.warn(selectionNotFound);
                    return;
                  } else if (
                    selectedMap?.commercial_artisanal_target_fish_type?.indexOf(
                      OTHER_FISHER
                    ) >= 0
                  ) {
                    if (!validateKeys(selectedMap, ["other_fisher_type"])) {
                      alertService.warn("Please input Other Fisher Type");
                      return;
                    }
                  }
                }

                if (
                  selectedMap.commercial_licence_type === INDUSTRIAL_FISHING &&
                  selectedMap.licenceTypeMapping === COMMERCIAL
                ) {
                  if (
                    !validateKeys(selectedMap, ["fishery_type_a"]) &&
                    !validateKeys(selectedMap, ["fishery_type_b"])
                  ) {
                    alertService.warn(selectionNotFound);
                    return;
                  }
                }

                if (
                  selectedMap.commercial_licence_type === "Carrier Fishing" &&
                  selectedMap.vessel_commercial_carrier_type ===
                    "Industrial Carrier" &&
                  selectedMap.licence_type === COMMERCIAL
                ) {
                  if (
                    !validateKeys(selectedMap, ["target_fish"]) &&
                    !validateKeys(selectedMap, ["special_target_fish"])
                  ) {
                    alertService.warn(selectionNotFound);
                    return;
                  }
                }

                if (
                  selectedMap.commercial_licence_type === "Carrier Fishing" &&
                  selectedMap.vessel_commercial_carrier_type ===
                    "Artisanal Carrier" &&
                  selectedMap.licence_type === COMMERCIAL
                ) {
                  if (!validateKeys(selectedMap, ["vessel_type"])) {
                    alertService.warn(selectionNotFound, {
                      autoClose: true,
                    });
                    return;
                  }
                }

                let isValid = true;
                if (selectedMap.licence_type === RECREATIONAL) {
                  // selectedMap.recreational_licence_type?.forEach((item) => {
                  //   if (
                  //     !validateKeys(selectedMap, [
                  //       recreation_type_of_licence[item].key,
                  //     ])
                  //   ) {
                  //     isValid = false;
                  //   }
                  // });

                    if (
                      !validateKeys(selectedMap, [
                        recreation_type_of_licence[selectedMap.recreational_licence_type].key,
                      ])
                    ) {
                      isValid = false;
                    }
                }

                if (isValid) {
                  if (selectedMap.licence_type === COMMERCIAL) {
                    setSelectionCategory(selectionCategory + 1);
                  } else {
                    setSelectionCategory(selectionCategory + 2);
                  }
                } else {
                  alertService.warn(selectionNotFound);
                  return;
                }
              }}
              prevValue={"Previous"}
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        </div>
      )}

      {selectionCategory === 6 && (
        <div style={getAnimationStyle(selectionCategory === 6)}>
          <BoxChoiceContainer
            listingHeading={"Select Vessel Class"}
            listingOptions={VesselClasses[selectedMap.licence_type]}
            prop_key={"commercial_vessel_class"}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            textStyle="rectangle-box-small-text"
            listingStyle="justify-content-center"
          />

          <InternalNavigator
            nextValue={"Next"}
            nextAction={async () => {
              if (!validateKeys(selectedMap, ["commercial_vessel_class"])) {
                alertService.warn(selectionNotFound);
                return;
              }
              setSelectionCategory(selectionCategory + 1);
            }}
            prevValue={"Previous"}
            previousAction={() => {
              if (
                selectedMap.licence_type !== ACCESS_AGREEMENTS_FOREIGN_FISHING
              ) {
                setSelectionCategory(selectionCategory - 1);
              } else if (
                selectedMap.licence_type === ACCESS_AGREEMENTS_FOREIGN_FISHING
              ) {
                // ACCESS_AGREEMENTS_FOREIGN_FISHING doesn't have any subcategory
                setSelectionCategory(selectionCategory - 3);
              }
            }}
          />
        </div>
      )}

      {selectionCategory === 7 && (
        <div style={getAnimationStyle(selectionCategory === 7)}>
          <VesselOwnerDetails
            formState={vodFormState}
            register={registerVOD}
            control={vodFormControl}
            onSubmit={onSubmit}
            setValue={setValue}
            reset={reset}
            values={getVesselOwnerDetailsValues}
            watch={watchVOD}
          />

          <InternalNavigator
            nextValue={"Next"}
            nextAction={async () => {
              if (await validateVODForm()) {
                setSelectionCategory(selectionCategory + 1);
              } else {
                alertService.warn(formInvalidMessage);
              }
            }}
            prevValue={"Previous"}
            previousAction={() => {
              if (
                selectedMap.licence_type !== ACCESS_AGREEMENTS_FOREIGN_FISHING
              ) {
                if (selectedMap.licence_type === COMMERCIAL) {
                  setSelectionCategory(selectionCategory - 1);
                } else {
                  setSelectionCategory(selectionCategory - 2);
                }
              } else if (
                selectedMap.licence_type === ACCESS_AGREEMENTS_FOREIGN_FISHING
              ) {
                // ACCESS_AGREEMENTS_FOREIGN_FISHING doesn't have any subcategory
                setSelectionCategory(selectionCategory - 4);
              }
            }}
          />
        </div>
      )}

      {selectionCategory === 8 && (
        <div style={getAnimationStyle(selectionCategory === 8)}>
          <VesselDetails
            register={registerVD}
            formState={vdFormState}
            watch={watchVD}
            control={vdFormControl}
            onSubmit={onSubmit}
            setValue={setVesselDetails}
          />

          <InternalNavigator
            nextValue={"Next"}
            nextAction={async () => {
              if (await validateVDForm()) {
                setSelectionCategory(selectionCategory + 1);
              } else {
                alertService.warn(formInvalidMessage);
              }
            }}
            prevValue={"Previous"}
            previousAction={() => {
              setSelectionCategory(selectionCategory - 1);
            }}
          />
        </div>
      )}

      {selectionCategory === 9 && (
        <div style={getAnimationStyle(selectionCategory === 9)}>
          <BoxChoiceContainer
            listingHeading={"Select Port or Inland"}
            listingOptions={baseLocations}
            selectedMap={selectedMap}
            setSelectedMap={setSelectedMap}
            selectionCategory={selectionCategory}
            prop_key={"port_or_inland"}
            onClick={() => setSelectionCategory(selectionCategory + 1)}
            listingStyle="justify-content-center"
            listingDescriptions={[null, "(Rivers and Ponds)"]}
            listingDisabled={[false, true]}
          />

          <div className="d-flex flex-column">
            <InternalNavigator
              nextValue={"Next"}
              nextAction={() => {
                if (!validateKeys(selectedMap, ["port_or_inland"])) {
                  alertService.warn(selectionNotFound);
                  return;
                }

                setSelectionCategory(selectionCategory + 1);
              }}
              prevValue="Previous"
              previousAction={() => {
                setSelectionCategory(selectionCategory - 1);
              }}
            />
          </div>
        </div>
      )}

      {selectionCategory === 10 &&
        selectedMap.port_or_inland === BASE_LOCATION_INLAND && (
          <div style={getAnimationStyle(selectionCategory === 10)}>
            {/* <BoxChoiceContainer
              listingHeading={"Select Inland"}
              listingOptions={InlandList}
              selectedMap={selectedMap}
              setSelectedMap={setSelectedMap}
              selectionCategory={selectionCategory}
              prop_key={"inland"}
              listingStyle="justify-content-center"
              boxStyle="sub-cat-container"
              textStyle="rectangle-box-small-text"
            />

            {selectedMap.inland === "Other" && (
              <CustomInputContainer
                heading={"Other"}
                prop_key={"other_inland"}
                placeholder={"Other"}
                selectedMap={selectedMap}
                setSelectedMap={setSelectedMap}
              ></CustomInputContainer>
            )} */}

            <div className="d-flex-wrap flex-row justify-content-center">
              <ReactSelect
                options={regionListForInland}
                label="Select Region/Parish"
                value={selectedMap.region}
                onChange={(e) => {
                  setSelectedMap((map) => {
                    return {
                      ...map,
                      region: e.target.value,
                      inland: "",
                    };
                  });
                }}
              />

              {selectedMap.region && (
                <ReactSelect
                  options={regionInland?.[selectedMap.region]}
                  value={selectedMap.inland}
                  label="Select Inland"
                  onChange={(e) => {
                    setSelectedMap((map) => {
                      return {
                        ...map,
                        inland: e.target.value,
                      };
                    });
                  }}
                />
              )}
            </div>

            <div className="d-flex flex-column">
              <InternalNavigator
                nextValue={"Next"}
                nextAction={() => {
                  if (!validateKeys(selectedMap, ["inland"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }

                  if (
                    selectedMap.inland === "Other" &&
                    !validateKeys(selectedMap, ["other_inland"])
                  ) {
                    alertService.warn(inputPreviousNumber("Other Inland"), {
                      autoClose: true,
                    });
                    return;
                  }

                  setSelectionCategory(selectionCategory + 2);
                }}
                prevValue="Previous"
                previousAction={() => {
                  setSelectionCategory(selectionCategory - 1);
                }}
              />
            </div>
          </div>
        )}

      {selectionCategory === 10 &&
        selectedMap.port_or_inland === BASE_LOCATION_PORT && (
          <div style={getAnimationStyle(selectionCategory === 10)}>
            <div className="d-flex-wrap flex-row justify-content-center">
              <ReactSelect
                options={regionParishList}
                label="Select Region/Parish"
                value={selectedMap.region}
                onChange={(e) => {
                  setSelectedMap((map) => {
                    return {
                      ...map,
                      region: e.target.value,
                      port: "",
                    };
                  });
                }}
              />

              {selectedMap.region && (
                <ReactSelect
                  options={regionParishPort?.[selectedMap.region]}
                  value={selectedMap.port}
                  label="Select Port"
                  onChange={(e) => {
                    setSelectedMap((map) => {
                      return {
                        ...map,
                        port: e.target.value,
                      };
                    });
                  }}
                />
              )}
            </div>

            <div className="d-flex flex-column">
              <InternalNavigator
                nextValue={"Next"}
                nextAction={() => {
                  if (!validateKeys(selectedMap, ["region", "port"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }

                  setSelectionCategory(selectionCategory + 1);
                }}
                prevValue="Previous"
                previousAction={() => {
                  setSelectionCategory(selectionCategory - 1);
                }}
              />
            </div>
          </div>
        )}

      {selectionCategory === 11 &&
        selectedMap.port_or_inland === BASE_LOCATION_PORT && (
          <div style={getAnimationStyle(selectionCategory === 11)}>
            <div className="d-flex flex-row justify-content-between">
              <div>
                {isLoaded && (
                  <div className="flex-grow-1 GoogleApp-small mt-4 ">
                    <GoogleMap
                      mapContainerClassName="map-container"
                      onLoad={onMapLoad}
                      onClick={() => setIsOpen(false)}
                    >
                      {fishingArea.map(({ location, lat, lng, id }, ind) => (
                        <Marker
                          key={id}
                          position={{ lat, lng }}
                          onClick={() => {
                            handleMarkerClick(id, lat, lng, location);
                            onChangeMultiSelect(
                              {
                                target: {
                                  value: location,
                                  name: "fishing_area",
                                },
                              },
                              selectedMap,
                              setSelectedMap
                            );
                          }}
                        >
                          {isOpen && infoWindowData?.id === id && (
                            <InfoWindow
                              onCloseClick={() => {
                                setIsOpen(false);
                              }}
                            >
                              <span>{infoWindowData.address}</span>
                            </InfoWindow>
                          )}
                        </Marker>
                      ))}
                    </GoogleMap>
                  </div>
                )}
              </div>

              <div className="ml-4 pl-2 d-flex flex-column flex-grow-1">
                <div className="rectangle-box-small-heading">
                  Select Fishing Area(s)
                </div>

                <div
                  className="d-flex-wrap flex-row justify-content-start"
                  style={{ margin: "10px 30px 26px 0px" }}
                >
                  {fishingAreaFishingVessel?.map((fishing_area, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() => {
                          onChangeMultiSelect(
                            {
                              target: {
                                value: fishing_area.location,
                                name: "fishing_area",
                              },
                            },
                            selectedMap,
                            setSelectedMap
                          );
                        }}
                        className="p-2 location-cat-container"
                        style={{
                          ...getRectBoxStylingWithMultiTypeValues(
                            selectedMap,
                            "fishing_area",
                            fishing_area.location
                          ),
                          maxWidth: "130px",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          {getSVGImage(
                            32,
                            32,
                            selectedMap["fishing_area"] ===
                              fishing_area.location
                              ? "black"
                              : "#cbf1ff"
                          )}

                          <div className="rectangle-box-small-text">
                            {fishing_area.location}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div></div>
              </div>
            </div>

            <div className="d-flex flex-column">
              <InternalNavigator
                nextValue={"Next"}
                nextAction={() => {
                  if (!validateKeys(selectedMap, ["fishing_area"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }

                  setSelectionCategory(selectionCategory + 1);
                }}
                prevValue="Previous"
                previousAction={() => {
                  setSelectionCategory(selectionCategory - 1);
                }}
              />
            </div>
          </div>
        )}

      {selectionCategory === 12 && (
        <div style={getAnimationStyle(selectionCategory === 12)}>
          <div>
            {/* gear type */}

            <div className="d-flex-wrap flex-column justify-content-between">
              <div className="rectangle-box-small-heading">
                Gear Information
              </div>

              <GearInformation
                formState={giFormState}
                register={registerGI}
                control={giFormControl}
                onSubmit={onSubmit}
                setValue={setGearInfoValues}
                values={getGearInfoValues}
                watch={watchGearInfo}
                disabled={false}
                source={Vessel_Fishing_GearType}
              />
            </div>

            <div className="d-flex flex-column">
              <InternalNavigator
                nextValue={"Next"}
                nextAction={async () => {
                  const gearInfo = await getGearInfoValues().gear_information;
                  const isFormValid = await triggerValidateGI();
                  if (!isFormValid) {
                    alertService.warn("Number of Gear is required", {
                      autoClose: true,
                    });
                    return;
                  }
                  const gearKeys = Object.keys(gearInfo);
                  const isChecked = gearKeys.some((key) => {
                    if (gearInfo[key].isChecked) return true;
                    else return false;
                  });
                  if (isChecked) {
                    setSelectedMap((map) => {
                      return {
                        ...map,
                        gear_information: gearInfo,
                      };
                    });
                  } else {
                    alertService.warn(selectionNotFound, {
                      autoClose: true,
                    });
                    return;
                  }
                  setSelectionCategory(selectionCategory + 1);
                }}
                prevValue="Previous"
                previousAction={() => {
                  if (selectedMap.port_or_inland === BASE_LOCATION_PORT) {
                    setSelectionCategory(selectionCategory - 1);
                  } else {
                    setSelectionCategory(selectionCategory - 2);
                  }

                  // setSelectionCategory(selectionCategory - 1);
                }}
              />
            </div>

            {/* total number of gears */}
          </div>
        </div>
      )}

      {selectionCategory === 13 && (
        <div style={getAnimationStyle(selectionCategory === 13)}>
          <div>
            {/* gear type */}

            <BoxChoiceContainer
              listingHeading={"Main Gear Owned By"}
              listingOptions={MainGearOwnedBy}
              selectedMap={selectedMap}
              setSelectedMap={setSelectedMap}
              selectionCategory={selectionCategory}
              prop_key={"main_gear_owned_by"}
              onClick={() => {
                setSelectedMap((map) => {
                  return {
                    ...map,
                    selectionCategory,
                  };
                });
                props?.next();
              }}
              listingStyle="justify-content-center"
            ></BoxChoiceContainer>

            <div className="d-flex flex-column">
              <InternalNavigator
                nextValue={"Next"}
                nextAction={() => {
                  if (!validateKeys(selectedMap, ["main_gear_owned_by"])) {
                    alertService.warn(selectionNotFound);
                    return;
                  }

                  setSelectedMap((map) => {
                    return {
                      ...map,
                      selectionCategory,
                    };
                  });
                  props?.next();
                }}
                prevValue="Previous"
                previousAction={() => {
                  setSelectionCategory(selectionCategory - 1);
                }}
              />
            </div>

            {/* total number of gears */}
          </div>
        </div>
      )}
    </div>
  );
};